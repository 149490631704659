import { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import PartnerDetail from "./PartnerDetail";
import Scrollbar from "components/ScrollBar";
import AllKyc from "./AllKyc";
import AllInventory from "./AllInventory";
// import PastTradePartnerDetails from "./PastTradePartnerDetails";
// import PastTradeKYC from "./PastTradeKYC";
// import PastTradeInventory from "./PastTradeInventory";

function PastTradeSidebar({ trade, stats }) {
  const [activeTab, setActiveTab] = useState(1);

  const changeActiveTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      // sx={{ borderLeft: 1, borderColor: "divider", height: "100vh" }}
      className="past-trade-sidebar"
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={activeTab}
          onChange={changeActiveTab}
        >
          <Tab label="Trade Summary" value={1} />
          {trade.otp_verified && <Tab label="KYC" value={2} />}
          {trade.giftCards?.length > 0 && <Tab label="Inventory" value={3} />}
        </Tabs>
      </Box>
      <Scrollbar
        id="myElement"
        // innerRef={simpleBarRef}
        // scrollToBottom={true}
        style={{
          height: 850,
        }}
      >
        <Box className="sidebar-content" id="past-trade-sidebar-content">
          {activeTab === 1 && <PartnerDetail trade={trade} stats={stats} />}
          {activeTab === 2 && trade.otp_verified && (
            <AllKyc trade={trade} stats={stats} />
          )}
          {activeTab === 3 && trade.giftCards?.length > 0 && (
            <AllInventory trade={trade} />
          )}
        </Box>
      </Scrollbar>
    </Box>
  );
}

export default PastTradeSidebar;
