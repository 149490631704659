import { useState } from "react";
import Calendar from "./Calender";
import Details from "./Details";
import moment from "moment-timezone";

function ScheduleViewer({ date, schedule, timezone }) {
  // const [data, setData] = useState(new Date());

  // const showDetailsHandle = (day) => {
  //   setData(day);
  // };
  console.log("date", date);

  return (
    <div className="App">
      <Calendar
        schedule={schedule}
        startFrom={date ? date : moment.tz(timezone).endOf("hour")}
        showDetailsHandle={() => {}}
        timezone={timezone}
      />
      <Details schedule={schedule} startFrom={date} timezone={timezone} />
    </div>
  );
}
ScheduleViewer.defaultProps = {
  date: new Date(),
};

export default ScheduleViewer;
