import React from "react";
import ATooltip from "./ATooltip";
import { Small, Span } from "components/Typography";
import { Box } from "@mui/material";
import { ShoppingCart } from "@mui/icons-material";

function TradeStatIcons({
  trade,
  chatCount,
  chatDisabled,
  chatBlink,
  attachmentCount,
  attachmentDisabled,
  attachmentBlink,
  otp_verified,
  trade_security,
  chatPlacement,
  attachmentPlacement,
  securityPlacement,
  otpPlacement,
  giftCards,
}) {
  const getSecurityType = (val) => {
    let message = "";
    switch (val) {
      case "pin":
        message = "Trade PIN";
        break;
      case "app":
        message = "Vendor App Approval";
        break;
    }
    return message;
  };

  return (
    <>
      <ATooltip
        content={`${chatCount} Total Messages`}
        placement={chatPlacement}
      >
        <div>
          <ChatIcon
            active={false}
            count={chatCount}
            disabled={chatDisabled}
            blink={chatBlink}
          />
        </div>
      </ATooltip>

      <ATooltip
        content={`${attachmentCount} Total Attachments`}
        placement={attachmentPlacement}
      >
        <div>
          <AttachmentIcon
            active={false}
            count={attachmentCount}
            disabled={attachmentDisabled}
            blink={attachmentBlink}
          />
        </div>
      </ATooltip>

      {otp_verified && (
        <ATooltip content={`KYC completed`} placement={otpPlacement}>
          <a style={{ marginLeft: "3px" }}>
            <span className="svg-icon svg-icon-muted svg-icon-2 me-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                  fill="currentColor"
                />
                <path
                  d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </a>
        </ATooltip>
      )}

      {trade_security && trade_security != "none" && (
        <ATooltip
          content={`Security Type: ${getSecurityType(trade_security)}`}
          placement={securityPlacement}
        >
          <span className=" me-1">
            <i
              className="fa-solid fa-lock"
              style={{
                color: "#2499EF",
                fontSize: "18px",
                marginTop: "2px",
                marginLeft: "5px",
              }}
            ></i>
          </span>
        </ATooltip>
      )}

      {giftCards && giftCards != null && giftCards?.length > 0 && (
        <ATooltip
          // offset={offset}
          content={"Inventory added"}
          // placement={securityPlacement}
        >
          <ShoppingCart color="primary" />
          {/* <KeenIcon icon={"handcart"} color={"primary"} /> */}
        </ATooltip>
      )}
    </>
  );
}

export default TradeStatIcons;

export const AttachmentIcon = ({ index, active, count, blink, disabled }) => (
  <>
    <a
      style={{ position: "relative", marginRight: count > 0 ? "5px" : " " }}
      id={`new-attachment-${index}`}
      className={`me-${count > 0 ? "3" : "2"} position-relative`}
    >
      <span
        className={`icon-chat-status svg-icon svg-icon-${
          active ? "primary" : "muted"
        } svg-icon-2 me-0${blink ? " highlight-danger" : ""}`}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
            fill="currentColor"
          />
          <path
            d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
            fill="currentColor"
          />
          <path
            opacity="0.3"
            d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
            fill="currentColor"
          />
        </svg>
      </span>
      {count > 0 && (
        <Small
          sx={{
            top: -18,
            left: 15,
            position: "absolute",
            width: "14px",
            height: "14px",
            borderRadius: "50%",
            color: "white",
            padding: " 1px 5px",
            fontSize: "9px",
            backgroundColor: "primary.main",
          }}
        >
          {count}
        </Small>
      )}
    </a>
  </>
);

export const ChatIcon = ({ index, active, blink, count, disabled }) => {
  return (
    <>
      <a
        style={{ position: "relative", marginRight: count > 0 ? "12px" : " " }}
        id={`new-message-${index}`}
      >
        <span
          className={`icon-chat-status svg-icon svg-icon-${
            active ? "primary" : "muted"
          } svg-icon-2 me-0${blink ? " highlight-danger" : ""}`}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z"
              fill="currentColor"
            />
            <rect
              x="6"
              y="12"
              width="7"
              height="2"
              rx="1"
              fill="currentColor"
            />
            <rect
              x="6"
              y="7"
              width="12"
              height="2"
              rx="1"
              fill="currentColor"
            />
          </svg>
        </span>
        {count > 0 && (
          <Small
            sx={{
              top: -18,
              left: 15,
              position: "absolute",
              width: "14px",
              height: "14px",
              borderRadius: "50%",
              color: "white",
              padding: " 1px 4px",
              fontSize: "9px",
              backgroundColor: "primary.main",
            }}
          >
            {count}
          </Small>
        )}
      </a>
    </>
  );
};
