import { useState } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  getDate,
  isBefore,
  isAfter,
} from "date-fns";
import moment from "moment";
import { Box } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

const Calendar = ({ startFrom, showDetailsHandle, schedule, timezone }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(moment.tz(timezone));

  const changeMonthHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    }
  };

  const onDateClickHandle = (day, dayStr) => {
    //setSelectedDate(day);
    //showDetailsHandle(day);
  };

  const renderHeader = () => {
    const dateFormat = "MMM yyyy";
    return (
      <Box className="header calendar-row flex-middle">
        <Box className="calendar-col col-start">
          {/* <Box className="icon" onClick={() => changeMonthHandle("prev")}>
            prev month
          </Box> */}
        </Box>
        <Box className="calendar-col col-center">
          <span>{format(currentMonth, dateFormat)}</span>
        </Box>
        <Box className="calendar-col col-end">
          {/* <Box className="icon" onClick={() => changeMonthHandle("next")}>next month</Box> */}
        </Box>
      </Box>
    );
  };
  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    //let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    let startDate = moment.utc(startFrom).tz(timezone);
    for (let i = 0; i < 7; i++) {
      days.push(
        <Box className="calendar-col col-center" key={i}>
          {startDate.clone().add(i, "days").format("ddd")}
        </Box>
      );
    }
    return (
      <Box
        sx={{
          border: "0.5px solid lightgray !important",
        }}
        className="days calendar-row"
      >
        {days}
      </Box>
    );
  };
  // onDateClickHandle(cloneDay, dayStr);

  const renderCells = () => {
    // const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    // const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    let startDate = moment.utc(startFrom).tz(timezone);
    const endDate = startDate.clone().add("6", "days");
    const dateFormat = "D";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = day.format(dateFormat);
        const cloneDay = day.clone();

        const weekDay = moment(day).tz(timezone).weekday();
        const daySchedule = schedule[`d_${weekDay}`];

        const selectedClass = day.isSame(selectedDate) ? "selected" : "";

        days.push(
          <Box
            sx={{
              border: "0.5px solid lightgray !important",
            }}
            className={`calendar-col cell ${
              day.isSame(moment.tz(timezone), "day") ? "today" : selectedClass
            }${daySchedule ? " has-schedule" : ""}${
              day.isBefore(moment.tz(timezone)) ? " past" : ""
            }`}
            key={day}
            onClick={() => {
              // const dayStr = format(cloneDay, "ccc dd MMM yy");
              // onDateClickHandle(cloneDay, dayStr);
            }}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>

            {daySchedule && (
              <>
                <Box className="check-icon">
                  <CheckCircle
                    sx={{ color: "success.main", fontSize: "1.2rem" }}
                  />
                </Box>
              </>
            )}
          </Box>
        );
        day = day.clone().add(1, "day");

        // day = addDays(day, 1);
      }

      rows.push(
        <Box className="calendar-row" key={day}>
          {days}
        </Box>
      );
      days = [];
    }
    return <Box className="body">{rows}</Box>;
  };

  //   return (
  //     <Box className="header calendar-row flex-middle">
  //       <Box className="calendar-col col-start">
  //         <Box className="icon" onClick={() => changeWeekHandle("prev")}>
  //           prev week
  //         </Box>
  //       </Box>
  //       <Box>{currentWeek}</Box>
  //       <Box
  //         className="calendar-col col-end"
  //         onClick={() => changeWeekHandle("next")}
  //       >
  //         <Box className="icon">next week</Box>
  //       </Box>
  //     </Box>
  //   );
  // };
  return (
    <Box className="schedule-calendar">
      {renderDays()}
      {renderCells()}
    </Box>
  );
};

export default Calendar;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
