import React from "react";
import ATooltip from "./ATooltip";
import country from "data/country";
import { Box } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";

export const getCountry = (code) => {
  const foundCountry = country.find((item) => {
    return item.code === code;
  });

  if (foundCountry) {
    return foundCountry.name;
  }

  return null;
};

function CountryFlagCode({ code, width }) {
  return (
    <FlexBox
      sx={{
        alignItems: "center",
      }}
    >
      {" "}
      <img
        src={`https://flagcdn.com/${code?.toLowerCase()}.svg`}
        width={width}
        alt={code}
      />
    </FlexBox>
  );
}

function CountryFlag({ CountryName, code, width, showToolTip = true }) {
  const data = getCountry(code);

  return (
    <>
      {showToolTip && (
        <ATooltip content={data}>
          <CountryFlagCode code={code} width={width} />
        </ATooltip>
      )}
      {!showToolTip && <CountryFlagCode code={code} width={width} />}
    </>
  );
}

CountryFlag.defaultProps = {
  width: "20px",
};

export default CountryFlag;
