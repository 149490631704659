import React, { useContext, useEffect, useState } from "react";
// import { Button, Card, CardBody } from "reactstrap";
// import emitter from "../../helpers/emitter";
// import CraftNotice from "../widgets/Notice";
// import Textarea from "./Textarea";
import auto_suggestions from "../../../../data/auto_suggestions";
import Lottie from "lottie-react-web";
import featuredPinLight from "../../../../assets/animations/Security-01-Light.json";
import featuredPinDark from "../../../../assets/animations/Security-01-Dark.json";
// import ImageGallery, { ImageGalleryContext } from "../widgets/ImageGallery";
import { useRef } from "react";
// import PaxfulProfilePic from "../widgets/PaxfulProfilePic";
import platforms from "../../../../data/platforms";
// import PlatformLogo from "../widgets/PlatformLogo";
// import { TextareaMessagesSkeleton } from "../skeleton/AutomationsSkeleton";
import Platform from "components/shared/Platform";
import CraftNotice from "components/CraftNotice";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import {
  Box,
  Button,
  Card,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ATCraftNotice from "components/ATCraftNotice";
import FlexBox from "components/flexbox/FlexBox";
import { H3, Paragraph, Small } from "components/Typography";
import { UserContext } from "page-sections/user-list/ViewUser";
import Textarea from "./Textarea";
import {
  BodyTableCell,
  HeadTableCell,
} from "page-sections/accounts/account/common/StyledComponents";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import Timezone from "components/shared/Timezone";
import RoleBadge from "utils/RoleBadge";
import CountryFlag from "components/shared/CountryFlag";
import UserSymbol from "components/shared/UserSymbol";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { getCountryNameByCode } from "utils/currencies";
import UserService from "services/UserService";
// import Textarea from "./Textarea";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import OTPInput from "./OTPInput";
import ConfirmBox from "./ConfirmBox";
import { SettingsContext } from "contexts/settingsContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));

const Member = ({ member, onSave, key }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const options = [
    {
      value: "none",
      label: "None",
    },
    {
      value: "static",
      label: "Trade PIN Code",
    },
    {
      value: "rotating",
      label: "Vendor App Approval",
    },
  ];

  const [editPIN, setEditPIN] = useState(false);
  const [showChangePinModal, setShowPinModal] = useState(false);
  const toggleChangePinModal = () => setShowPinModal(!showChangePinModal);
  const [showConfirm, setShowConfirm] = useState(false);

  const [selectedOption, setSelectedOption] = useState({
    value: "none",
    label: "None",
  });

  const [state, setState] = useState({
    pin_type: "none",
    pin: "",
  });

  const prevOption = useRef(null);
  const currentOption = useRef(null);

  useEffect(() => {
    setSelectedOption(
      options.find((_option) => _option.value == member.pin_type)
    );
    if (member.pin_type == "none") {
      setEditPIN(true);
    }
    setState({
      pin_type: member.pin_type,
    });
  }, []);

  const handleSelectChange = (event) => {
    let val = options.find(({ value }) => {
      return value == event.target.value;
    });
    prevOption.current = selectedOption;
    currentOption.current = val;
    //setSelectedOption(val);
    setState({
      ...state,
      pin_type: val.value,
    });

    if (val.value == "none" && member.pin_type != "none") {
      //setEditPIN(true);
      // saveSecurityPin(_val);
      setShowConfirm(true);
    }
    if (val.value !== "none") {
      //setEditPIN(true);
      //saveSecurityPin(_val);
      setShowPinModal(true);
    }
  };

  const onAlertConfirm = () => {
    prevOption.current = null;
    saveSecurityPin({ pin_type: "none" });
    setShowConfirm(false);
  };

  const onAlertClose = () => {
    currentOption.current = selectedOption;
    setShowConfirm(false);
  };

  useEffect(() => {
    if (prevOption.current && showConfirm === false) {
      setSelectedOption(prevOption.current);
      setState({
        ...state,
        pin_type: prevOption.current.value,
      });
    }
  }, [showConfirm]);

  const handleInputComplete = (value) => {
    const _val = {
      ...state,
      pin: value,
    };
    setState(_val);
    saveSecurityPin(_val);
  };

  const handleModalClose = () => {
    if (state.pin !== " ") {
      setSelectedOption(
        options.find((_option) => _option.value == member.pin_type)
      );
    }
    toggleChangePinModal();
  };

  const saveSecurityPin = async (value) => {
    const resp = await UserService.updateSecurityPin(member._id, value);
    if (resp.status) {
      setShowPinModal(false);
      setShowConfirm(false);
      if (currentOption.current) {
        setSelectedOption(currentOption.current);
        currentOption.current = null;
      }
    }
  };

  return (
    <>
      <StyledTableRow key={key}>
        <BodyTableCell className="ps-0">
          <span className="user-widget">
            <FlexBox
              sx={{ alignItem: "center" }}
              className="d-flex align-items-center"
            >
              <UserSymbol
                size={40}
                user={member}
                showOnline
                isBottomZero={25}
              />
              <FlexBox
                ml={2}
                sx={{ flexDirection: "column" }}
                className="d-flex flex-column ms-3"
              >
                <FlexBox className="text-gray-800 mb-1" alignItems="center">
                  {member.username}
                  <span
                    style={{
                      marginLeft: "5px",
                    }}
                    className="ms-2"
                  >
                    {/* <CountryFlag
                      code={member.country}
                      width={"18px"}
                      content={getCountryNameByCode(member.country)}
                      id={member._id}
                    /> */}
                  </span>
                </FlexBox>
                <Small>{member.email}</Small>
                {member.email == null && (
                  <span>{formatPhoneNumberIntl(member.mobile_number)}</span>
                )}
                <Small>
                  <RoleBadge role={member.role} />
                </Small>
              </FlexBox>
            </FlexBox>
          </span>
        </BodyTableCell>
        <BodyTableCell>
          <div>
            <span className="me-1 text-dark">
              {getCountryNameByCode(member.country)}
            </span>
            <span
              style={{
                marginLeft: "5px",
              }}
              className="ms-2"
            >
              <img
                src={`https://flagcdn.com/${member.country?.toLowerCase()}.svg`}
                width={"18px"}
                id={member._id}
              />
            </span>
          </div>
          <span>
            <span className="d-block">{member.timezone}</span>
            {member.timezone ? (
              <Timezone
                timezone={member.timezone}
                id={member._id}
                showTooltip={false}
                onTeam={true}
                // backgroundColor={"#f5f8fa"}
                // color={"#1b1b29"}
              />
            ) : (
              "-"
            )}
          </span>
        </BodyTableCell>
        {/* <td>
          <UserRole user={member} />
        </td> */}
        <BodyTableCell width={"240px"}>
          <span
            className={`form-control-solid${
              member.role == "admin" ? " is--disabled" : ""
            }`}
          >
            {/* <Select
              isDisabled={member.role == "admin"}
              options={options}
              value={selectedOption}
              onChange={handleSelectChange}
              classNamePrefix={"atlantis-select"}
              isSearchable={false}
              placeholder="Pin Type"
            /> */}
          </span>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              disabled={true}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedOption.value}
              label="Age"
              onChange={handleSelectChange}
            >
              {options.map((option, key) => {
                return <MenuItem value={option.value}>{option.label}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </BodyTableCell>
        <BodyTableCell className="text-end" width={"175px"}>
          <span className="position-relative">
            {/* {state.pin_type !== "none" && editPIN && (
              <>
                <span>
                  <Pin onComplete={handleInputComplete} />
                </span>               
              </>
            )} */}
            {member.pin_type && member.pin_type !== "none" && (
              <>
                <span className="fw-bolder fs-2">* * * *</span>
                <div>
                  <a
                    style={{ pointerEvents: "none" }}
                    disabled={true}
                    // style={{
                    //   cursor: "pointer",
                    // }}
                    onClick={toggleChangePinModal}
                    className="cursor-pointer text-primary"
                  >
                    Change PIN
                  </a>
                </div>
              </>
            )}
          </span>
        </BodyTableCell>
      </StyledTableRow>

      {/* Modal */}

      <Modal
        open={showChangePinModal}
        onClose={handleModalClose}
        size="sm"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box p={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Set {member.first_name}&apos;s Security PIN
            </Typography>
          </Box>

          <Box
            sx={{
              borderBottom: "0.5px solid lightgray",
            }}
          ></Box>

          <Box p={2}>
            <ATCraftNotice
              type={"primary"}
              bodyPadding="3"
              className={"mb-5"}
              message={`Security PINs are not viewable after creation and a new one must be set by the vendor if a team member forgets their PIN.`}
            />
            <Box mt={4} className="change-security-pin text-center mt-10">
              <OTPInput onComplete={handleInputComplete} length={4} />
            </Box>
          </Box>
        </Box>
      </Modal>
      {member && (
        <ConfirmBox
          showConfirm={showConfirm}
          title={<H3>{member.first_name}'s Trade Permissions</H3>}
          confirmButtonLabel="Remove"
          cancelButtonLabel={"Cancel"}
          onConfirm={onAlertConfirm}
          onCancel={onAlertClose}
          content={
            <Paragraph fontSize={13}>
              <span>
                Security type: <strong>{member.pin_type}</strong>
              </span>
              <br />
              <br />
              Are you sure you want to remove{" "}
              {member.first_name + " " + member.last_name} trade security pin?
            </Paragraph>
          }
        />
      )}
    </>
  );
};

function PinSecurity({ teamMembers, activeAccount, profile }) {
  const { userdata, offers } = useContext(UserContext);
  const { settings, saveSettings } = useContext(SettingsContext);

  //const fliteredMembers = teamMembers?.filter(({ role }) => role !== "admin");

  //let is_owner_online = false;
  let membersToSort = teamMembers;
  let owner = null;

  //let num_online = 0;

  if (teamMembers) {
    teamMembers.forEach((member) => {
      // let is_online = moment(member.last_active).isAfter(
      //   moment().subtract(5, "minutes")
      // );

      if (member.role == "admin") {
        //is_owner_online = is_online;
        owner = member;
      }
      //if (is_online) num_online++;
    });

    //if (is_owner_online || num_online == 0) {
    //filter out admin from list
    membersToSort = [...membersToSort].filter((member) => {
      return member.role != "admin";
    });
    //}
  }

  let membersByActivity =
    teamMembers && membersToSort
      ? [...membersToSort].sort(function (a, b) {
          let today = new Date();
          let _a = a.last_active
            ? new Date(a.last_active)
            : today.setFullYear(today.getFullYear() - 1);
          let _b = b.last_active
            ? new Date(b.last_active)
            : today.setFullYear(today.getFullYear() - 1);

          return _b - _a;
        })
      : null;

  //if (is_owner_online || num_online == 0) {
  if (owner) membersByActivity = [owner].concat(membersByActivity);
  return (
    <>
      <Box py={2} px={4}>
        <ATCraftNotice
          icon={
            <svg
              width="40"
              height="40"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                opacity="0.3"
                x="2"
                y="2"
                width="20"
                height="20"
                rx="10"
                fill="currentColor"
              />
              <path
                d="M15.8054 11.639C15.6757 11.5093 15.5184 11.4445 15.3331 11.4445H15.111V10.1111C15.111 9.25927 14.8055 8.52784 14.1944 7.91672C13.5833 7.30557 12.8519 7 12 7C11.148 7 10.4165 7.30557 9.80547 7.9167C9.19432 8.52784 8.88885 9.25924 8.88885 10.1111V11.4445H8.66665C8.48153 11.4445 8.32408 11.5093 8.19444 11.639C8.0648 11.7685 8 11.926 8 12.1112V16.1113C8 16.2964 8.06482 16.4539 8.19444 16.5835C8.32408 16.7131 8.48153 16.7779 8.66665 16.7779H15.3333C15.5185 16.7779 15.6759 16.7131 15.8056 16.5835C15.9351 16.4539 16 16.2964 16 16.1113V12.1112C16.0001 11.926 15.9351 11.7686 15.8054 11.639ZM13.7777 11.4445H10.2222V10.1111C10.2222 9.6204 10.3958 9.20138 10.7431 8.85421C11.0903 8.507 11.5093 8.33343 12 8.33343C12.4909 8.33343 12.9097 8.50697 13.257 8.85421C13.6041 9.20135 13.7777 9.6204 13.7777 10.1111V11.4445Z"
                fill="currentColor"
              />
            </svg>
          }
          type={"primary"}
          title={
            <FlexBox
              color="black"
              sx={{ alignItem: "center" }}
              className="d-flex align-items-center"
            >
              <FlexBox className="d-flex align-items-baseline">
                {"Set Automatic Trade Security Measures for"}
              </FlexBox>

              <FlexBox
                sx={{
                  alignItem: "center",
                  position: "relative",
                  marginLeft: "5px",
                }}
                className="user-inner-wrap mt-1 mb-1"
              >
                <UserSymbol user={profile} size={20} />

                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  {profile?.username}&apos;s team
                </span>
              </FlexBox>
            </FlexBox>
          }
          message={`Auto Trade Security allows the vendor to assign static PIN codes to themselves & to team members that must be entered when initating a trade release on Venva. For vendors who want maximum security, we also offer vendor trade approval push notifications, where the vendor must approve trade releases by their team members before they are successfully released. Please note all security features on Venva are optional and must be enabled.`}
        />
        <Box
          py={2}
          sx={{ borderBottom: "1px dashed lightgrey" }}
          className="separator separator-dashed my-5 my-xl-10"
        ></Box>
        <Grid container spacing={3} className="mb-1">
          <Grid item lg={2} xl={2} className="d-none d-xl-block left">
            {/* Your Lottie animation code */}
            <Box sx={{ transform: " translate3d(0px, 62px, 0px)" }}>
              {settings && settings.theme == "light" && (
                <Lottie
                  options={{
                    animationData: featuredPinLight,
                  }}
                  // width="100%"
                />
              )}
              {settings && settings.theme == "dark" && (
                <Lottie
                  options={{
                    animationData: featuredPinDark,
                  }}

                  // width="100%"
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={10} xl={10} className="mid">
            <Card className="card-shadow overflow--hidden">
              <Grid container justifyContent="flex-start" mt={3}>
                <Grid item md={12}>
                  <TableContainer component={Paper} sx={{ minHeight: "500px" }}>
                    <Table aria-label="simple table">
                      <TableHead
                        sx={{
                          borderBottom: "1px solid",
                          borderColor: "divider",
                        }}
                      >
                        <TableRow>
                          <HeadTableCell>
                            <Paragraph color="text.secondary">USER</Paragraph>{" "}
                          </HeadTableCell>
                          <HeadTableCell>
                            <Paragraph color="text.secondary">
                              TIMEZONE
                            </Paragraph>{" "}
                          </HeadTableCell>
                          <HeadTableCell>
                            <Paragraph color="text.secondary">
                              SECURITY TYPE
                            </Paragraph>
                          </HeadTableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {teamMembers && teamMembers.length === 0 && (
                          <StyledTableRow
                            sx={{
                              borderBottom: "1px solid lightgray",
                            }}
                            // key={index}
                          >
                            <BodyTableCell colSpan={4} className="text-center">
                              <span className="text-muted">
                                No team member yet.{" "}
                                <Link href="/team?add=member">
                                  <a>Invite a team member</a>
                                </Link>{" "}
                                to get started
                              </span>
                            </BodyTableCell>
                          </StyledTableRow>
                        )}
                        {teamMembers &&
                          membersByActivity.map((member, index) => {
                            return <Member key={index} member={member} />;
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default PinSecurity;
