import {
  Box,
  Button,
  Chip,
  IconButton,
  Popover,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import { H6, Paragraph, Small, Span, Tiny } from "components/Typography";
import { format } from "date-fns";
import MoreHorizontal from "icons/MoreHorizontal";
import {
  FormattedDate,
  FormattedDateTime,
  FromNow,
} from "components/shared/FormattedDate";
import PaxfulAccountPreview from "components/shared/PaxfulAccountPreview";
import { ArrowOutward, ContentCopy } from "@mui/icons-material";
import { Link } from "react-router-dom";
import TradePreview from "components/shared/TradePreview";
import UserSymbol from "components/shared/UserSymbol";
import styled from "@emotion/styled";
import CustomChip from "components/shared/CustomChip";
import Platform from "components/shared/Platform";
import FlexBox from "components/flexbox/FlexBox";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import ATooltip from "components/shared/ATooltip";
import CheckmarkCircle from "icons/CheckmarkCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CountryFlag, { getCountry } from "components/shared/CountryFlag";
import OnlineStatus from "components/shared/OnlineStatus";
import MfaCheck from "components/shared/MfaCheck";
import DeleteButton from "page-sections/profiles/profile/DeleteButton";
import EditButton from "page-sections/profiles/profile/EditButton";
import PasswordButton from "page-sections/profiles/profile/PasswordButton";
import RoleBadge from "utils/RoleBadge";
import LockButton from "page-sections/profiles/profile/overview/LockButton";
import DiscordAvatar from "components/avatars/DiscordAvatar";
import GoogleIcon from "icons/GoogleIcon";
import SignInMethod from "components/shared/SignInMethod";
import notification from "helpers/notifications";
import PersonOffButton from "page-sections/profiles/profile/SessionButton";
import { formatCurrency } from "utils/currencies";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import { useState } from "react";
import TradePartnerDetails from "page-sections/profiles/buyer/TradePartnerDetails";
import { isCountryCodeExists, isCountryNameExists } from "utils/utils";

const StyledCheckMark = styled(CheckmarkCircle)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.success.main,
}));

const StyledCancelIcon = styled(CancelIcon)(({ theme }) => ({
  maxWidth: 16,
  fill: theme.palette.error.main,
}));

const StyledButton = styled(Button)`
    padding: 4,
    minWidth: 60,
    marginRight: 3,
  `;

const StyledTableCell = styled(TableCell)(() => ({
  paddingBottom: 0,
  borderBottom: 0,
  "&:nth-of-type(2)": {
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  "& td:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  "& td:last-of-type": {
    textAlign: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));
const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  borderBottom: 0,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-child": {
    textAlign: "center",
  },
  "&:last-of-type": {
    paddingRight: 16,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  padding: "10px 10px",
  color: theme.palette.text.primary,
}));

const GooglePicture = styled("img")`
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;

// anchorEl,
// handleClick,
// _handleClose,
// _open,
// id
const copyTextToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success("Coppied to clipboard", text.slice(0, 100));
};

export const PartnersColumnShape = [
  {
    Header: "ID",
    accessor: "_id",
    Cell: ({ value }) => (
      <>
        <Box
          sx={{
            maxWidth: "40px",
            cursor: "pointer",
          }}
        >
          <ATooltip content={value}>
            <Span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                copyTextToClipboard(value);
              }}
            >
              {value.slice(0, 2)}...
              {value.slice(value.length - 3, value.length)}
            </Span>
          </ATooltip>
        </Box>
      </>
    ),
  },

  {
    Header: () => <Box>TRADE PARTNER</Box>,
    accessor: "partner",
    Cell: ({ value, row }) => {
      const [showConfirm, setShowConfirm] = useState(false);
      const [selectedPartner, setSelectedPartner] = useState(null);

      const toggleShowConfirm = (partnerId) => {
        setSelectedPartner(partnerId);
        setShowConfirm((prev) => !prev);
      };
      console.log("rows", row);

      return (
        <>
          <FlexBox
            alignItems="center"
            onClick={() => toggleShowConfirm(row.original._id)}
            cursor="default"
          >
            <PaxfulProfilePic account={value.profile} buyer />
            <Box
              sx={{
                marginLeft: "10px !important",
                minWidth: "150px !important",
              }}
            >
              <Paragraph
                fontSize={13}
                color="text.secondary"
                style={{ cursor: "pointer" }}
              >
                <a>{value.username} </a>
              </Paragraph>
            </Box>
            {value.platform && <Platform platform={value.platform} size={20} />}
          </FlexBox>
          {showConfirm && (
            <TradePartnerDetails
              partner_id={selectedPartner}
              showConfirm={showConfirm}
              onCancel={() => {
                setShowConfirm(false);
                setSelectedPartner(null);
              }}
            />
          )}
        </>
      );
    },
  },
  {
    Header: () => <Box>LOCATION </Box>,
    accessor: "location",
    Cell: ({ value }) => (
      <FlexBox style={{ cursor: "default", alignItems: "center" }}>
        {/* <CountryFlag
          code={value.detected_location.iso}
          width="16px"
          // content={countryName}
          // showToolTip={false}
        /> */}
        {/* <img
          src={`https://flagcdn.com/${value.detected_location.iso?.toLowerCase()}.svg`}
          width="16px"
        />
        <Span
          marginLeft={"4px"}
          fontSize={13}
          color="text.secondary"
          cursor="text"
        >
          {getCountry(value.detected_location.iso)}{" "}
        </Span> */}
        <CountryFlag
          code={
            isCountryCodeExists(value.detected_location.iso)
              ? value.detected_location.iso
              : value?.ip_location?.iso
          }
          width={"18px"}
          showToolTip={false}
        />
        <Span
          sx={{
            marginLeft: "5px",
            textAlign: "center",
          }}
          fontSize={13}
        >
          {isCountryNameExists(value.detected_location.name)
            ? value.detected_location.name
            : value?.ip_location?.name}
        </Span>
      </FlexBox>
    ),
  },
  {
    Header: () => <Box>TRADING VOLUME </Box>,
    accessor: "trading_volume",
    Cell: ({ value }) => (
      <Box style={{ cursor: "text" }}>{formatCurrency(value)}</Box>
    ),
  },

  {
    Header: () => <Box sx={{ marginLeft: "8px" }}>JOINED</Box>,
    accessor: "sdsddwwww",
    Cell: ({ row }) => {
      const { joined } = row.original.partner.profile;
      return (
        <Box style={{ cursor: "text" }}>
          {joined && (
            <Box
              sx={{
                minWidth: "150px",
              }}
            >
              <Paragraph fontSize={13} color="text.secondary">
                {joined}
              </Paragraph>
            </Box>
          )}
        </Box>
      );
    },
  },
];
export default PartnersColumnShape;
