import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Divider,
  Stack,
  Typography,
  Chip,
  Badge,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CountryFlag from "components/shared/CountryFlag";
import { FormattedDateTime, FromNow } from "components/shared/FormattedDate";
import Platform from "components/shared/Platform";
import TradeServices from "services/TradeService";
import FlexBox from "components/flexbox/FlexBox";
import PaxfulProfilePic from "components/shared/PaxfulProfilePic";
import { ProfileImageWithAccount } from "components/shared/PartnerDetails";
import DateTime from "components/shared/DateTime";
import CreatedBy from "components/shared/CreatedBy";
import BadgeMui from "components/shared/BadgeMui";
import { Paragraph, Small } from "components/Typography";
import InfiniteScroll from "react-infinite-scroll-component";
import KYCService from "services/KYCService";
import { UserContext } from "page-sections/user-list/ViewUser";
import { formatCurrency } from "utils/currencies";
import { isCountryCodeExists, isCountryNameExists } from "utils/utils";
import TrimmedText from "components/TrimmedText";

export const LoadingMore = () => (
  <div className="d-flex text-center mt-5 justify-content-center align-items-center overflow-hidden">
    <i className={`fa fa-circle-notch fa-spin text-primary fa-lg`}></i>
    <span className="ms-2">Loading...</span>
  </div>
);

function AllKyc({ trade, stats }) {
  const { userdata } = useContext(UserContext);

  const [kycs, setkycs] = useState(null);
  const [count, setCount] = useState(null);
  // const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllKyc = async (buyer_id, user_id, skip, limit) => {
    setLoading(true);
    const resp = await KYCService.getAllKyc(buyer_id, user_id, skip, limit);
    if (resp.status) {
      setkycs(skip > 0 ? kycs?.concat(resp.data) : resp.data);
      setCount(resp.count);
      setLoading(false);
    }
  };

  // const getVendorDetails = async (account_id, buyer_id) => {
  //   const resp = await TradeServices.getVendorDetails(account_id, buyer_id);
  //   if (resp.status == true) {
  //     setStats(resp.data);
  //   }
  // };
  const getMoreKYC = () => {
    getAllKyc(trade.buyer_id, userdata._id, kycs.length, 20);
  };

  useEffect(() => {
    // getVendorDetails(trade?.account._id, trade?.buyer_id);
    getAllKyc(trade.buyer_id, userdata._id, 0, 20);
  }, []);

  return (
    <>
      {loading ? (
        <>
          {" "}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            {" "}
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Box p={2} pb={2} className="prev-kyc-tile">
            <FlexBox sx={{ alignItems: "center" }}>
              {trade?.buyer && (
                <ProfileImageWithAccount
                  account={trade.buyer}
                  pfSize={30}
                  buyer
                  platform={trade.account.platform}
                  platformSize={15}
                />
              )}
              <Box ml={0.8}>
                <Paragraph fontSize={15}>
                  {trade.buyer.username} &apos;s KYC
                </Paragraph>
              </Box>
            </FlexBox>
            {trade?.otpVerifiedAt && (
              <Box
                sx={{
                  border: "1px solid lightgrey",
                  marginBottom: "5px",
                  borderRadius: "10px",
                }}
                className="prev-kyc-tile"
                p={1}
              >
                {/* <Small fontSize={13} fontWeight={400}>
                    {getBuyerFullName(trade)}
                  </Small> */}

                <FlexBox
                  mb={0.5}
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    // backgroundColor: "aliceblue",
                    // borderRadius: "10px",
                  }}
                >
                  <FlexBox sx={{ alignItems: "center" }}>
                    <CountryFlag
                      code={trade.mobileLookup.lookup_info.countryCode}
                      width="18px"
                      showToolTip={false}
                    />
                    <Paragraph color="" fontSize={12} fontWeight={500} ml={1}>
                      {trade.mobileLookup.lookup_info.phoneNumber}
                    </Paragraph>
                  </FlexBox>

                  <small className="fw-semibold">
                    {trade.otpVerifiedAt ? (
                      <BadgeMui color="success.main">Verified</BadgeMui>
                    ) : (
                      <BadgeMui color="error.main">Not Verified</BadgeMui>
                    )}
                  </small>
                </FlexBox>
                <FlexBox
                  sx={{ justifyContent: "space-between" }}
                  className="d-flex flex-stack"
                >
                  <div className="fs-7">
                    <Paragraph color="" fontSize={12} fontWeight={500}>
                      <TrimmedText
                        text={trade.mobileLookup.lookup_info.carrier.name}
                        length={26}
                        subtract={1}
                      />
                    </Paragraph>
                  </div>
                  <div>
                    <BadgeMui color="primary.main">
                      {trade.mobileLookup.lookup_info.carrier.type}
                    </BadgeMui>
                  </div>
                </FlexBox>
                <Box mb={2} className="d-flex flex-stack mt-1">
                  <Small
                    sx={{ display: "block" }}
                    fontSize={12}
                    fontWeight={500}
                  >
                    {trade.otpVerifiedAt && (
                      <DateTime date={trade?.otpVerifiedAt} />
                    )}
                  </Small>
                  <Small
                    sx={{
                      display: "block",
                      width: 100,
                      fontSize: 10,
                      borderRadius: "4px",
                      textAlign: "center",
                      // padding: ".2rem 1rem",
                      backgroundColor: "lightgrey",
                      color: "black",
                      margin: ".3rem 0rem",
                    }}
                  >
                    <FromNow date={trade?.otpVerifiedAt} />
                  </Small>
                  <Small fontSize={12}>
                    {trade?.KYC_verifiedBy && (
                      <CreatedBy user={trade?.KYC_verifiedBy} verified />
                    )}
                  </Small>
                </Box>
              </Box>
            )}

            {trade.trade.offer_type == "sell" && (
              <div className="mt-6">
                <Typography
                  fontSize={14}
                  color="text.secondary"
                  fontWeight={600}
                >
                  Name
                </Typography>
                <Paragraph fontSize={13}>
                  {trade.trade.buyer_full_name.first_name
                    ? trade.trade.buyer_full_name.first_name
                    : "None"}{" "}
                  {trade.trade.buyer_full_name.last_name}
                </Paragraph>
              </div>
            )}

            {trade.trade.offer_type == "buy" && (
              <div className="mt-6">
                <Typography
                  fontSize={14}
                  color="text.secondary"
                  fontWeight={600}
                >
                  Name
                </Typography>
                <Paragraph fontSize={13}>
                  {trade.trade.seller_full_name.first_name
                    ? trade.trade.seller_full_name.first_name
                    : "None"}{" "}
                  {trade.trade.seller_full_name.last_name}
                </Paragraph>
              </div>
            )}

            {trade.partner_location && (
              <>
                {trade.partner_location.detected_location && (
                  <Box mb={2} sx={{ marginTop: "6px" }}>
                    <Typography
                      fontSize={14}
                      color="text.secondary"
                      fontWeight={600}
                    >
                      Country
                    </Typography>
                    <FlexBox sx={{ alignItems: "center" }}>
                      <CountryFlag
                        code={
                          isCountryCodeExists(
                            trade.partner_location.detected_location.iso
                          )
                            ? trade.partner_location.detected_location.iso
                            : trade.partner_location?.ip_location?.iso
                        }
                        width={"18px"}
                        showToolTip={false}
                      />
                      <Paragraph
                        sx={{
                          marginLeft: "5px",
                          textAlign: "center",
                        }}
                        fontSize={13}
                      >
                        {isCountryNameExists(
                          trade.partner_location.detected_location.name
                        )
                          ? trade.partner_location.detected_location.name
                          : trade.partner_location?.ip_location?.name}
                      </Paragraph>
                    </FlexBox>
                  </Box>
                )}
              </>
            )}
            {trade && (
              <Box>
                <Typography
                  fontSize={14}
                  color="text.secondary"
                  fontWeight={600}
                >
                  Venva Trading Statistics
                </Typography>
                <div
                  style={{
                    marginTop: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 8,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography fontSize={13} fontWeight={500}>
                    {" "}
                    Daily Trading Total
                  </Typography>
                  <Paragraph fontSize={13} fontWeight={500}>
                    {stats ? formatCurrency(stats.daily_total) : "$0"}
                  </Paragraph>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 8,
                    justifyContent: "space-between",
                  }}
                >
                  <Typography fontSize={13} fontWeight={500}>
                    {" "}
                    Weekly Trading Total
                  </Typography>
                  <Paragraph fontSize={13} fontWeight={500}>
                    {" "}
                    {stats ? formatCurrency(stats.weekly_total) : "$0"}
                  </Paragraph>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography fontSize={13} fontWeight={500}>
                    {" "}
                    Lifetime Trading Total
                  </Typography>
                  <Paragraph fontSize={13} fontWeight={500}>
                    {stats ? formatCurrency(stats.lifetime_total) : "$0"}
                  </Paragraph>
                </div>
              </Box>
            )}
          </Box>
          {kycs && (
            <div className="kyc-sidebar-wrapper">
              <Kycs
                kycs={kycs}
                count={count}
                onNext={getMoreKYC}
                trade={trade}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default AllKyc;

const Kycs = ({ kycs, count, onNext, trade }) => {
  return (
    <>
      <InfiniteScroll
        dataLength={kycs.length}
        next={onNext}
        hasMore={kycs.length < count}
        loader={<LoadingMore />}
        scrollableTarget={"kyc-sidebar-wrapper"}
        scrollThreshold={0.9}
      >
        <Typography
          fontSize={13}
          color="text.secondary"
          fontWeight={600}
          marginLeft={2}
        >
          KYC History
        </Typography>
        {kycs &&
          kycs.map((item, index) => {
            return (
              <>
                {item.trade?.otpVerifiedAt && (
                  <Box
                    p={2}
                    pb={0}
                    className="prev-kyc-tile"
                    key={`kyc_${index}`}
                  >
                    {/* <Small fontSize={13} fontWeight={400}>
                    {getBuyerFullName(trade)}
                  </Small> */}

                    <FlexBox
                      mb={0.5}
                      // p={1}
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between",
                        // backgroundColor: "aliceblue",
                        // borderRadius: "10px",
                      }}
                    >
                      <FlexBox sx={{ alignItems: "center" }}>
                        <CountryFlag
                          code={item.mobileLookup.lookup_info.countryCode}
                          width="18px"
                          showToolTip={false}
                        />
                        <Paragraph
                          color=""
                          fontSize={15}
                          fontWeight={500}
                          ml={1}
                        >
                          {item.mobileLookup.mobile_number}
                        </Paragraph>
                      </FlexBox>

                      <small className="fw-semibold">
                        {item?.trade?.otp_verified ? (
                          <BadgeMui color="success.main">Verified</BadgeMui>
                        ) : (
                          <BadgeMui color="error.main">Not Verified</BadgeMui>
                        )}
                      </small>
                    </FlexBox>
                    <FlexBox
                      sx={{ justifyContent: "space-between" }}
                      className="d-flex flex-stack"
                    >
                      <div className="fs-7">
                        <Paragraph color="" fontSize={12} fontWeight={500}>
                          {item.mobileLookup.lookup_info.carrier.name}
                        </Paragraph>
                      </div>
                      <div>
                        <BadgeMui color="primary.main">
                          {item.mobileLookup.lookup_info.carrier.type}
                        </BadgeMui>
                      </div>
                    </FlexBox>
                    <Box mb={2} className="d-flex flex-stack mt-1">
                      <Small
                        sx={{ display: "block" }}
                        fontSize={12}
                        fontWeight={500}
                      >
                        {item?.trade?.otpVerifiedAt && (
                          <DateTime date={item?.trade?.otpVerifiedAt} />
                        )}
                      </Small>
                      <Small
                        sx={{
                          display: "block",
                          width: 100,
                          fontSize: 10,
                          borderRadius: "4px",
                          textAlign: "center",
                          // padding: ".2rem 1rem",
                          backgroundColor: "lightgrey",
                          color: "black",
                          margin: ".3rem 0rem",
                        }}
                      >
                        <FromNow date={item.trade?.otpVerifiedAt} />
                      </Small>
                      <Small fontSize={12}>
                        {item?.trade?.KYC_verifiedBy && (
                          <CreatedBy
                            user={item?.trade?.KYC_verifiedBy}
                            verified
                          />
                        )}
                      </Small>
                    </Box>
                    {index !== kycs.length - 1 && <Divider />}
                  </Box>
                )}
              </>
            );
          })}
      </InfiniteScroll>
    </>
  );
};

export function getBuyerFullName(trade) {
  if (
    trade.trade.offer_type === "sell" &&
    trade.trade?.buyer_full_name &&
    trade.trade.buyer_full_name.first_name
  ) {
    return `${trade.trade.buyer_full_name.first_name} ${trade.trade.buyer_full_name?.last_name}`;
  } else if (
    trade.trade.offer_type === "buy" &&
    trade.trade?.seller_full_name &&
    trade.trade.seller_full_name.first_name
  ) {
    return `${trade.trade.seller_full_name.first_name} ${trade.trade.seller_full_name?.last_name}`;
  } else {
    return null;
  }
}
